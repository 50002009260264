import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

const TsImage2 = () => {
  const data = useStaticQuery(graphql`{
  placeholderImage: file(relativePath: {eq: "ts/1V6A8212.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}`)

  return <GatsbyImage image={data.placeholderImage.childImageSharp.gatsbyImageData} />;
}

export default TsImage2
