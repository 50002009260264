import React from "react"
import { Row, Col, Container, Carousel } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import TsImage1 from "../components/TsImage1"
import TsImage2 from "../components/TsImage2"
import TsImage3 from "../components/TsImage3"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }} style={{
        background: `white`,
      }}>
    <Seo title="TrackSecure Startseite" keywords={[ `Rennstrecke`, `Trackday`, `fahren`, `safety`, `Auto`, `Tracktool`, `Fahrsicherheit`, `Sicherheit`, `Mobilität` ]} />
    <Container className="text-center">
      <Row>
        <Col>
            <p className="content-text"><br/>Willkommen bei TrackSecure! <br/>Wir entwickeln digitale Produkte und Lösungen für sichere Mobilität.</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Carousel>
            <Carousel.Item>
              <TsImage1 />
              <Carousel.Caption>
                <h3>TrackSecure...</h3>
                <p>...steigert die Sicherheit beim sportlichen Fahren.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <TsImage2 />
              <Carousel.Caption>
                <h3>TrackSecure...</h3>
                <p>...informiert Dich über Hindernisse auf der Strecke.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <TsImage3 />
              <Carousel.Caption>
                <h3>TrackSecure...</h3>
                <p>...ermöglicht Dir Kontakt zu Dienstleistern und Gleichgesinnten.</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
